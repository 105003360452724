import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import MediaQuery from "react-responsive";

import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Button from "../components/Button";
import Accordion from "../components/Accordion";

import "./about-page.sass";

const CONTENT_DELIMITER =
  '<section class="cloud-delimiter do-not-delete-or-amend"></section>';

export const AboutPageTemplate = ({
  content,
  questions,
  helmet,
  groundFloorDesktop,
  groundFloorMobile,
  contentComponent,
  hundredLogo
}) => {
  const PageContent = contentComponent || Content;
  const [contentPOne, contentPTwo] = content.split(CONTENT_DELIMITER);

  return (
    <article className="about-page">
      {helmet || ""}

      <section className="top">
        <div className="container">
          <Img
            className="logo"
            fluid={hundredLogo.childImageSharp.fluid}
            alt="100 Story Building"
          />
          <PageContent className="content" content={contentPOne} />
        </div>
      </section>

      <section className="cloud-break" />

      <section className="cloud-flex">
        <div className="container">
          <PageContent className="content" content={contentPTwo} />

          <div className="learn-more">
            <Button externalRef="https://www.100storybuilding.org.au/">
              Learn More
            </Button>
          </div>
        </div>
      </section>

      <section className="main-img-wrap">
        <MediaQuery query="(min-width: 521px)">
          <Img
            className="logo"
            fluid={groundFloorDesktop.childImageSharp.fluid}
            alt="100 Story Building Ground Floor"
          />
        </MediaQuery>
        <MediaQuery query="(max-width: 520px)">
          <Img
            className="logo"
            fluid={groundFloorMobile.childImageSharp.fluid}
            alt="100 Story Building Ground Floor"
          />
        </MediaQuery>
      </section>

      <section className="bottom">
        <div className="container">
          <h2 className="faqs">F.A.Qs</h2>
          <div className="accordion-wrap">
            <Accordion items={questions} />
          </div>

          <div className="still-got-questions">
            <h3>Still got questions?</h3>
            <p>
              We'd love to hear from you, please{" "}
              <a href="mailto:info@100storybuilding.org.au">email us here.</a>
            </p>
          </div>
        </div>
      </section>
    </article>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const AboutPage = ({ data, location: { href } }) => {
  const { markdownRemark: post } = data;
  const { groundFloorDesktop, groundFloorMobile, hundredLogo } = data;

  return (
    <Layout currentPageUrl={href}>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        questions={post.frontmatter.questions}
        content={post.html}
        helmet={
          <Helmet>
            <title>{`About Us | 100 Story Building`}</title>
            <meta
              name="description"
              content="Find out more about 100 Story Building"
            />
            {/* <!-- Twitter Card data --> */}
            <meta
              name="twitter:title"
              content={`About Us | 100 Story Building`}
            />
            <meta
              name="twitter:description"
              content="Find out more about 100 Story Building"
            />

            {/* <!-- Open Graph data --> */}
            <meta
              property="og:title"
              content={`About Us | 100 Story Building`}
            />
            <meta
              property="og:description"
              content="Find out more about 100 Story Building"
            />
            <meta property="og:type" content="article" />
          </Helmet>
        }
        groundFloorDesktop={groundFloorDesktop}
        groundFloorMobile={groundFloorMobile}
        hundredLogo={hundredLogo}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    hundredLogo: file(relativePath: { eq: "static/plain-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    groundFloorDesktop: file(
      relativePath: { eq: "static/about-ground-floor-desktop.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    groundFloorMobile: file(
      relativePath: { eq: "static/about-ground-floor-mobile.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        questions {
          question
          answer
        }
      }
    }
  }
`;
