import React, { useState } from "react";

import openIcon from "../../img/plus-icon.svg";
import closedIcon from "../../img/minus-icon.svg";

import "./style.sass";

const CLOSED = "closed";
const OPEN = "open";

const AccordionItem = ({ label, content }) => {
  const [state, setState] = useState(CLOSED);

  const toggleState = () => {
    return setState(state === CLOSED ? OPEN : CLOSED);
  };

  const isCollapsed = state === CLOSED;
  return (
    <div onClick={toggleState} className="accordion-item">
      <button>
        <div className="button-inner">
          <div>{label}</div>
          <div>
            {state === OPEN ? (
              <img src={closedIcon} alt="closed" />
            ) : (
              <img src={openIcon} alt="open" />
            )}
          </div>
        </div>
      </button>
      <div className={`collapse-content ${state}`} aria-expanded={isCollapsed}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

const Accordion = ({ items }) => {
  return (
    <div className="accordion">
      {items.map(({ question, answer }) => (
        <AccordionItem label={question} content={answer} key={question} />
      ))}
    </div>
  );
};

export default Accordion;
